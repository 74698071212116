<template>
  <section>
    <navigation-bar
      v-if="isLoggedIn"
      :userName="userName"
      :userInitials="initials"
      @user-logout="logout"
    ></navigation-bar>
    <side-navigation>
      <slot />
    </side-navigation>
  </section>
</template>

<script>
import SideNavigation from "@/components/ui/SA/sideNavAdmin.vue";
export default {
  name: "saContainer",
  components: {
    SideNavigation,
  },
  methods: {
    async logout() {
      const tokenPayload = localStorage.getItem("token");
      try {
        await this.$store.dispatch("auth/logout", tokenPayload);
        this.$router.push("/");
      } catch (err) {
        this.error = err.message || "Something went wrong, try later.";
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    userName() {
      return this.$store.getters["auth/userName"];
    },
    initials() {
      return localStorage.getItem("initials");
    },
  },
};
</script>
