<template>
  <!-- Sidebar starts -->
  <div class="flex flex-no-wrap h-screen bg-lightGrey pt-16 overflow-hidden">
    <!-- <div
      class="w-80 bg-teal shadow flex-col justify-between hidden lg:flex overflow-scroll overflow-x-hidden"
    >
      <div class="px-8 pt-14">
        <div v-for="(category, index) in categories" :key="index" class="pt-4">
          <div
            @click="category.open = !category.open"
            class=" flex w-full text-white cursor-pointer items-center mb-1hover:text-darkGrey"
          >
            <div class="py-1 px-3 flex items-center justify-center text-xs">
              <span class="material-icons"> {{ category.icon }} </span>
            </div>
            <div class="flex items-end">
              <span class="text-sm">{{ category.name }}</span>
            </div>
          </div>
          <ul v-show="category.open" class="mt-2">
            <li
              v-for="(item, innerIndex) in category.items"
              :key="innerIndex"
              class="flex w-full text-white cursor-pointer items-center mb-2 ml-4 hover:text-darkGrey"
            >
              <div class=" px-4 flex items-center justify-center text-xs">
                >
              </div>
              <div class="flex items-end">
                <router-link :to="{ name: item.link }"
                  ><span class="text-sm">{{ item.name }}</span></router-link
                >
              </div> -->
    <!-- Notification -->
    <!-- <div
                class="py-1 px-1 ml-2 bg-red rounded-full text-white flex items-center justify-center text-xs"
              >
                5
              </div> -->
    <!-- Notification -->
    <!-- </li>
          </ul>
        </div>
      </div>
    </div> -->
    <!-- Main Sidebar Ends-->
    <!-- Collapse Start-->
    <div
      class="z-30 absolute top-16 transition duration-150 ease-in-out "
      id="mobile-nav"
    >
      <div
        id="openSideBar"
        class="h-10 w-10 bg-teal absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer"
        @click="sidebarHandler(true)"
      >
        <span
          class="material-icons text-white"
          :class="{ 'transform rotate-180': moved }"
        >
          menu_open
        </span>
      </div>
      <div
        class="w-64 bg-teal shadow  overflow-scroll overflow-x-hidden"
        id="forHeight"
      >
        <div class="px-8 pt-14">
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="pt-4"
          >
            <span
              @click="category.open = !category.open"
              class=" flex w-full text-white cursor-pointer items-center mb-1hover:text-darkGrey"
            >
              <div class="py-1 px-3 flex items-center justify-center text-xs">
                <span class="material-icons"> {{ category.icon }} </span>
              </div>
              <div class="flex items-end">
                <span class="text-sm">{{ category.name }}</span>
              </div>
            </span>
            <ul v-show="category.open" class="mt-2">
              <li
                v-for="(item, innerIndex) in category.items"
                :key="innerIndex"
                class="flex w-full text-white cursor-pointer items-center mb-2 ml-4 hover:text-darkGrey"
              >
                <div class=" px-4 flex items-center justify-center text-xs">
                  >
                </div>
                <div class="flex items-end">
                  <router-link :to="{ name: item.link }"
                    ><span class="text-sm">{{ item.name }}</span></router-link
                  >
                </div>
                <!-- Notification -->
                <!-- <div
                class="py-1 px-1 ml-2 bg-red rounded-full text-white flex items-center justify-center text-xs"
              >
                5
              </div> -->
                <!-- Notification -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Sidebar ends -->
    <!-- Content Start -->
    <central-card>
      <slot></slot>
    </central-card>
    <!-- Content Ends -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      moved: false,
      categories: {
        practicesGroup: {
          name: "Practice Management",
          icon: "home",
          open: false,
          items: [{ name: "Create Practice", link: "sa-manage-practices" }],
        },
        policiesGroup: {
          name: "Policies Management",
          icon: "description",
          open: false,
          items: [{ name: "Add Policy", link: "sa-manage-policies" }],
        },
        usersGroup: {
          name: "Users Management",
          icon: "person",
          open: false,
          items: [{ name: "Add User", link: "sa-manage-users" }],
        },
        rolesGroup: {
          name: "Roles Management",
          icon: "work",
          open: false,
          items: [{ name: "Create Role", link: "sa-manage-roles" }],
        },
        permissionsGroup: {
          name: "Permissions Management",
          icon: "fact_check",
          open: false,
          items: [{ name: "Create Permission", link: "sa-manage-permissions" }],
        },
        roomsGroup: {
          name: "Rooms Management",
          icon: "meeting_room",
          open: false,
          items: [{ name: "Create Room", link: "sa-manage-rooms" }],
        },
        reasonsGroup: {
          name: "Reasons Management",
          icon: "online_prediction",
          open: false,
          items: [{ name: "Create Reason", link: "sa-manage-reasons" }],
        },
      },
    };
  },
  methods: {
    sidebarHandler() {
      var sideBar = document.getElementById("mobile-nav");
      sideBar.style.transform = "translateX(-260px)";
      if (this.moved) {
        sideBar.style.transform = "translateX(0px)";
        this.moved = false;
      } else {
        sideBar.style.transform = "translateX(-260px)";
        this.moved = true;
      }
    },
  },
  watch: {
    $route() {
      if (!this.moved) {
        this.sidebarHandler();
      }
    },
  },
};
</script>

<style scoped>
a.router-link-exact-active {
  color: #425563;
  font-weight: 700;
}
#forHeight {
  height: calc(100vh - 64px);
}
</style>
